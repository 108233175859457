import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import ImageWithLogo from "../components/ImageWithLogo"
import HeadTitle from "../components/HeadTitle"
import PageHeadline from "../components/PageHeadline"
import { Parallax, ParallaxProvider } from "react-scroll-parallax"
import ContactBlock from "../components/ContactBlock"
import { AttachedImages } from "../Functions/AttachedImages"
import Puff from "../components/puff"
import Img from "gatsby-image"


export default ({ data }) => {
  // const post = data.allWordpressWpTalk.edges[0].node
  const otherPosts = data.allWordpressWpTalk.nodes;
  console.log('other posts: ', otherPosts);
  const post = data.wordpressWpTalk;
  // console.log('POST: ',post);

  const images = AttachedImages();
  const featured_image = post.featured_media ? post.featured_media.source_url : false;

  let puff = <Puff page={post.slug} />;
  const imageRes = post.featured_media.localFile.childImageSharp.fluid;

  // const contentImage = featured_image ? <img alt={post.title} src={`${post.featured_media.source_url}`} /> : <Img className="content-image" fluid={images.forelasning.childImageSharp.fluid} alt="Föreläsning och individuell coachning" />;
  const contentImage = featured_image ? <img alt={data.title} className="content-image" src={imageRes.src} /> : <Img className="content-image" fluid={images.forelasning.childImageSharp.fluid} alt="Föreläsning och individuell coachning" />;

  const acf = post.acf || false;
  const lecture = {
    name: acf ? acf.name : false,
    category: acf ? acf.category : false,
    preamble: acf ? acf.preamble : false,
    quote: acf ? acf.quote : false,
    text: acf ? acf.lower_text : false
  }

  return (
    
    <Layout>

      <div className="page forelasningar">

      <ParallaxProvider>

      <PageHeadline data={post} />

      <article className="">
            <div className="bg--pink bg--left container scroll-point" style={{ background: acf.bg_color }}>

          <div className="content">

            <div className="content__box align--left fade-in">
              <HeadTitle size="medium" title={lecture.name} subtitle={lecture.category} data={post} heading={2} />

              <p dangerouslySetInnerHTML={{ __html: lecture.preamble }} />
            </div>


            <Parallax y={[-60, -30]}>
              <ImageWithLogo img={contentImage} alignLogo="left" />
            </Parallax>

            <div className="scroll-point parallax-quote" offset="-250%">
              <Parallax x={[30, -40]}>
                <blockquote dangerouslySetInnerHTML={{ __html: lecture.quote }} />
              </Parallax>
            </div>


            <div className="content__box align--right fade-in arrow-left">
              <p dangerouslySetInnerHTML={{ __html: lecture.text }} />
            </div>
          </div>

        </div>

      </article>

          <div className="puff-block" >
            <div className="container">

              <h3 className="title--normal">Fler föreläsningar</h3>

              {otherPosts.map(otherPost=>(
                <Puff list
                  color={otherPost.acf ? otherPost.acf.bg_color : false}
                  link={`/forelasningar/${otherPost.slug}`}
                  title={otherPost.title}
                  text={otherPost.excerpt}
                  post={otherPost}
                />
              ))}
          </div>
      </div>
      

      <ContactBlock post={post} />

      </ParallaxProvider>
      </div>
    </Layout>
  )
}
// allWordpressWpTalk(filter: { slug: { eq: $slug } }){
// edges{
//   node{
//     title
//     content
//     acf {
//       ingress
//       underrubrik
//       lower_text
//       quote
//       name
//       preamble
//       category
//       bg_color
//     }
//     featured_media {
//       source_url
//       localFile {
//         childImageSharp {
//           fluid(maxWidth: 1000, quality: 100) {
//                   ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   }
// }
//     }
export const query = graphql`
  query($slug: String!){
    wordpressWpTalk(slug: { eq: $slug } ){
      title
      content
      slug
      acf {
        ingress
        underrubrik
        lower_text
        quote
        name
        preamble
        category
        bg_color
      }
      featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allWordpressWpTalk(filter: { slug: { ne: $slug } }){
      nodes{
        title
        content
        excerpt
        slug
        acf {
          ingress
          underrubrik
          lower_text
          quote
          name
          preamble
          category
          bg_color
        }
        featured_media {
          source_url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

// acf {
//   related_pages{
//     title
//     image_link{
//       source_url
//     }
//     lead
//     page{
//       post_name
//     }
//   }
